input:focus-visible {
  outline: none;
}

/* 메인 */
.setting_page_wrap {
  padding: 50px 0px;
}

.user_intro {
  padding: 25px 20px 30px;
}

.user_intro > .body2 {
  margin-top: 6px;
}

.setting_page_wrap .link {
  height: 50px;
  padding: 0px 20px;
  border-bottom: 1px solid var(--gray5);
}

.setting_page_wrap .link:first-child {
  border-top: 1px solid var(--gray5);
}

/* 관리자 페이지 */
.setting_wrap {
  padding-top: 50px;
}

.setting_container {
  padding: 15px 20px;
  height: 650px;
}

.setting_wrap .setting_tab {
  border-bottom: 1px solid var(--gray4);
}

.setting_wrap .setting_tab .setting_tab_box {
  width: 50%;
  font-size: 16px;
  text-align: center;
  padding: 15px;
  margin: 0 20px;
  border-bottom: 2px solid #fff;
  font-weight: 600;
  color: var(--gray3);
}

.setting_wrap .setting_tab .setting_tab_box.active {
  color: var(--black);
  border-bottom: 2px solid var(--black);
}

.setting_intro_title .label1 {
  padding-bottom: 6px;
}

.setting_intro_title .body2 {
  line-height: 18px;
  padding-bottom: 25px;
}

.keyword_box {
  padding-bottom: 15px;
}

.keyword_box p {
  padding-bottom: 10px;
}

.keyword_box input {
  width: 100%;
  height: 44px;
  padding: 10px 8px;
  border-radius: 5px;
  border: 1px solid var(--gray3);
}

.keyword_box .pass_input {
  position: relative;
}

.btn_off {
  position: absolute;
  top: 30%;
  right: 27%;
}

.keyword_box .label2 > p {
  padding-top: 4px;
}

.keyword_box textarea {
  width: 100%;
  height: 200px;
  padding: 10px;
  line-height: 24px;
}

.keyword_box .emailCheck {
  height: 15px;
  width: 15px;
  margin-right: 5px;
  margin-left: 0px;
  cursor: pointer;
}

/* 관리자 버튼 */
.setting_btn button {
  width: 100%;
  background: var(--secondary);
  color: #fff;
  border-radius: 10px;
  padding: 16px 0;
  margin-bottom: 50px;
}

.pass_input > input {
  width: 80%;
}

.pass_btn {
  width: 20%;
  height: 44px;
  margin-left: 12px;
  background: var(--primary);
  color: #fff;
  border-radius: 8px;
}

.btn_item {
  padding: 6px 20px;
  border-radius: 25px;
  margin: 0 8px 10px 0;
}

/* 주식 종목 */
.search_wrap .keyword_box .sc-guDLey {
  height: 140px;
}

.search_wrap .input_search {
  font-size: 14px;
  padding-left: 35px;
  background-image: url(../assets/search.png) !important;
  background-repeat: no-repeat !important;
  background-position: 10px 10px !important;
}

.search_wrap .sc-guDLey > li {
  font-size: 14px;
  padding: 6px 20px 6px 38px;
  transition: background-color 0.2s;
  background-image: url(../assets/search.png);
  background-repeat: no-repeat;
  background-position: 10px 6px;
}

/* TSB 설정 */
.tsb_setting_wrap > .title1 {
  padding: 30px 20px 18px;
}

.tsb_tab_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: var(--gray3);
  font-weight: 700;
  padding: 12px;
  border-bottom: 1px solid var(--gray4);
}

.tsb_tab_container .active {
  color: var(--gray1);
}

.keyword_border .pass_input {
  padding-bottom: 16px;
  border-bottom: 1px solid var(--gray5);
  margin-bottom: 12px;
}

/* 키워드 */
.keywords > span {
  display: inline-block;
  margin: 0 16px 6px 0;
  background-image: url('../assets/remove.png');
  background-size: 16px 16px;
  background-position: 100% 0;
  background-repeat: no-repeat;
  display: inline-flex;
}

.keywords > span .remove {
  width: 16px;
  height: 16px;
  margin: 0px 0px 0px 10px;
}

/* 키워드검색 인풋박스 선택시 나오는 창 */
.keyword_box {
  position: relative;
}

.keyword_box .sc-guDLey {
  position: absolute;
  top: 64px;
  width: 77.5%;
  height: 95px;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  border-top: 1px solid var(--gray5) !important;
  border: 1px solid var(--gray3);
  border-radius: 0px 0px 5px 5px;
  box-shadow: none;
}

.keyword_box .sc-guDLey li {
  padding: 6px 10px;
}

.keywords .keywords_drop_wrap .d-between {
  width: 100%;
  justify-content: space-between;
}

.news_setting_keywords .keywords > span {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-position: 100% 0px;
}

.bTljQC > li.hover {
  background-color: var(--gray5) !important;
  color: #333 !important;
}

.search_wrap .input_search {
  font-size: 14px;
  padding-left: 35px;
  background-image: url(../assets/search.png) !important;
  background-repeat: no-repeat !important;
  background-position: 10px 10px !important;
}

.search_wrap .sc-guDLey > li {
  font-size: 14px;
  padding: 6px 20px 6px 38px;
  background-image: url(../assets/search.png);
  background-repeat: no-repeat;
  background-position: 10px 6px;
}

.dropdown {
  position: relative;
  display: inline-block;
  height: 24px;
}

.dropdown-content {
  position: absolute;
  top: 30px;
  right: 0px;
  display: none;
  width: 140px;
  border-radius: 5px;
  border: 1px solid var(--gray4);
  background: #fff;
  box-shadow: 3px 4px 0.8px 0px rgba(0, 0, 0, 0.03);
  z-index: -1;
}

.dropdown-content a:hover {
  background-color: var(--gray5);
}

.dropdown-content.visible {
  display: block;
  z-index: 1;
}
