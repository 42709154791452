.techmeme_box {
  border-radius: 5px;
  background: var(--BG);
  margin-bottom: 14px;
  padding: 10px 15px;
}

.techmeme_box .lebel1 {
  font-weight: 600;
  padding-top: 4px;
}

.techmeme_box .body2.gray3 {
  padding-bottom: 8px;
}
