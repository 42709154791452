.patents_wrap {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--gray5);
}

.patents_wrap .patents_img {
  width: 50%;
  margin: 0 auto;
}

.patents_wrap .patents_img img,
.more_btn .body1 {
  width: 100%;
}

.patents_wrap .scroll {
  max-height: 110px;
  margin-top: 10px;
}

.more_btn .body1 img {
  margin-left: 4px;
}

.patent_flag {
  border-radius: 100px;
  border: 1px solid var(--gray4);
  margin: 20px 10px 0 0;
}
