.signup_nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 14px 10px;
  background: #fff;
}

.signup_nav a {
  display: block;
  width: 186px;
  padding: 10px;
}

/* 로그인 */
.login_wrap {
  width: 100%;
  height: 100vh;
  padding: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_wrap .title1 {
  font-size: 22px;
  padding-bottom: 45px;
}

.login_wrap input {
  width: 100%;
  height: 48px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--gray3);
  margin-bottom: 14px;
  font-size: 16px;
}

.login_wrap input:focus-visible {
  outline: none;
}

.login_wrap .login_btn {
  width: 100%;
  height: 50px;
  padding: 10px;
  border-radius: 5px;
  background: var(--primary);
  color: #fff;
  margin-top: 24px;
}

.login_wrap .find_wrap {
  color: var(--gray2);
  padding: 16px 0 30px;
  margin-bottom: 22px;
  border-bottom: 1px solid var(--gray5);
}

.login_wrap .link a {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background: var(--gray5);
  color: var(--black);
  margin-bottom: 10px;
  justify-content: center;
}

.login_wrap .link a > .d-flex {
  width: 140px;
}

.login_wrap .link .d-start p {
  margin-left: 10px;
}

.login_input > label > .d-flex {
  margin: 0 10px 20px 0;
}

.login_input input.login_set_input {
  width: 16px;
  height: 16px;
  padding: 0;
  margin: 0 6px 0 0;
  border-radius: 50%;
  cursor: pointer;
}

.login_input [type='radio'] {
  appearance: none;
  box-shadow: 0 0 0 1px var(--gray3);
  border: 3px solid #fff;
  background-color: #fff;
}

.login_input [type='radio']:checked {
  box-shadow: 0 0 0 1px var(--primary);
  border: 3px solid #fff;
  background-color: var(--primary);
}

.checkbox {
  width: 100%;
  color: var(--gray3);
  display: flex;
  align-items: center;
}

.checkbox input {
  position: relative;
  width: 18px;
  height: 18px;
  padding: 0;
  border: 1px solid var(--gray3);
  border-radius: 3px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  margin: 0 8px 0 0;
}

.checkbox input::before {
  content: '\2713';
  width: 18px;
  height: 18px;
  border: 1px solid var(--primary);
  border-radius: 3px;
  background-color: var(--primary);
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  line-height: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.checkbox input:checked::before {
  opacity: 1;
}

/* 회원가입 */
.signup_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0;
}

.signup_container {
  width: 100%;
  padding: 0px 20px;
}

.signup_wrap .heading1 {
  margin: 64px 0 30px;
}

input:focus-visible {
  outline: none;
}

.signupForm_wrap .btn {
  width: 23%;
  height: 48px;
  padding: 14px;
  border-radius: 8px;
  background: var(--primary);
  color: #ffff;
  border: none;
  margin-left: 2%;
}

.signupForm_wrap .btn:hover {
  background: var(--primary-D);
}

.signupForm_wrap .sign_btn {
  width: 100%;
  height: 50px;
  padding: 8px 18px;
  margin-top: 40px;
  border-radius: 5px;
  background: var(--secondary);
  color: #fff;
}

/* 개인정보 입력 */
.form_box {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.form_box .label1 {
  margin-bottom: 10px;
}

.form_box .label1 span,
.consentForm_wrap .consent_cont > p span {
  color: var(--red);
}

.form_box input {
  width: 100%;
  height: 48px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--gray3);
  background: #fff;
}

.form_box input.email {
  width: 75%;
}

.form_box .body3 {
  color: var(--gray2);
  margin-top: 6px;
}

.form_box textarea {
  resize: none;
  width: 100%;
  height: 150px !important;
  padding: 5px 0 5px 10px;
  border-radius: 5px;
  border: 1px solid var(--gray3);
  background: #fff;
}

.form_box textarea:focus-visible {
  outline: none;
}

/* 약관동의 */
.consentForm_wrap {
  word-break: keep-all;
}

.consentForm_wrap .consent_cont {
  margin-bottom: 30px;
}

.signup_wrap .consent_cont > p {
  color: var(--black);
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 10px;
}

.signup_wrap .scroll_wrap {
  width: 100%;
  height: 150px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid var(--gray3);
  background: #fff;
}

.signup_wrap .scroll_box {
  width: 100%;
  height: 130px;
  padding-right: 10px;
  overflow-y: scroll;
  display: inline-block;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.14px;
  color: var(--gray2);
}

.signup_wrap .scroll_box span {
  font-weight: 600;
}

.signup_wrap .scroll_box .body2,
.signup_wrap label.body2 {
  color: var(--gray2);
}

.scroll_box::-webkit-scrollbar {
  width: 5px; /* 스크롤바 너비 */
}

.scroll_box::-webkit-scrollbar-thumb {
  height: 50%; /* 스크롤바 길이 */
  background: var(--gray3); /* 스크롤바 색상 */
  border-radius: 20px;
}

.scroll_box::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(--BG); /* 스크롤바 뒷배경 색상 */
}

.consentForm_wrap table {
  width: 320px;
  text-align: center;
  margin: 10px 0;
  font-size: 12px;
  line-height: 16px;
  word-break: keep-all;
}

.consentForm_wrap table thead,
.consentForm_wrap .table_title {
  background: var(--BG);
  font-weight: 700;
}

.consentForm_wrap table tbody {
  vertical-align: middle;
}

.consentForm_wrap th,
.consentForm_wrap td {
  border: 1px solid var(--gray5);
  padding: 4px;
}
