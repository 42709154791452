html {
  box-sizing: border-box;
  font-size: 14px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration-line: none;
  color: #333;
}

button {
  border: none;
  background: transparent;
  padding: 0;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}
