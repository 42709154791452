.event_wrap > .d-flex {
  padding: 10px 0;
  border-bottom: 1px solid var(--gray5);
  align-items: flex-start;
}

.event_wrap > .d-flex:last-child {
  border-bottom: none;
}

.event_ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 84px;
  padding-left: 12px;
}

.event_ul > li > p:last-child {
  margin-right: 10px;
}

.event_img {
  width: 84px;
  height: 84px;
  object-fit: cover;
  border-radius: 8px;
}
