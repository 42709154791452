.react-chatbot-kit-chat-header,
.options-header,
.react-chatbot-kit-chat-btn-send svg,
.react-chatbot-kit-user-avatar {
  display: none;
}

.react-chatbot-kit-chat-bot-message span,
.react-chatbot-kit-user-chat-message,
.option-item {
  font-size: 13px;
  line-height: 24px;
}

.app-chatbot-container {
  width: 360px;
  height: auto;
  max-height: 480px;
  margin-left: 5px;
  position: fixed;
  bottom: 100px;
  padding: 10px 0 10px 15px;
  z-index: 9999;
  background: var(--BG);
  border-radius: 15px;
  box-shadow: 3px 1px 5px var(--gray4);
}

.app-chatbot-container input {
  position: relative;
  font-size: 12px;
  width: 100%;
  height: 35px;
  margin-right: 15px;
  padding: 12px 14px;
  border: none;
  border-radius: 10px;
}

.react-chatbot-kit-chat-btn-send {
  position: absolute;
  width: 46px;
  height: 46px;
  right: 20px;
  bottom: 5px;
  background-image: url(../assets/send.png);
  background-repeat: no-repeat;
  background-color: transparent !important;
  background-position: 15px 15px;
}

.react-chatbot-kit-user-chat-message-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.react-chatbot-kit-chat-bot-message-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 10px 0;
}

.react-chatbot-kit-user-chat-message {
  position: relative;
  display: flex;
  background-color: var(--primary5) !important;
  margin: 10px 0;
  padding: 6px 12px;
  border-radius: 10px;
}

.react-chatbot-kit-chat-bot-message {
  position: relative;
  height: fit-content;
  background-color: #fff !important;
  padding: 6px 12px;
  border-radius: 10px;
  margin-left: 10px;
}

.options-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.option-item {
  width: fit-content;
  color: var(--gray1);
  padding: 5px 7px;
  margin: 3px 0;
  border: 1px solid var(--gray4);
  border-radius: 10px;
  background: var(--primary5);
}

.react-chatbot-kit-chat-input-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.react-chatbot-kit-chat-bot-message span {
  white-space: pre-line;
}

.react-chatbot-kit-chat-bot-avatar-container,
.react-chatbot-kit-chat-bot-avatar-container img {
  width: 36px !important;
  height: 36px !important;
}

.react-chatbot-kit-chat-message-container {
  overflow-y: scroll;
  max-height: 350px;
  padding-right: 15px;
}

.react-chatbot-kit-chat-message-container::-webkit-scrollbar {
  width: 6px;
}

.react-chatbot-kit-chat-message-container::-webkit-scrollbar-thumb {
  background: var(--gray4);
  border-radius: 4px;
  background-clip: padding-box;
  border: 1px solid transparent;
}

.react-chatbot-kit-chat-message-container::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 4px;
  margin: 10px;
}

/* 챗 버튼 */
.app-chatbot-button {
  position: fixed;
  width: 45px;
  height: 45px;
  right: 19px;
  bottom: 35px;
  z-index: 99999;
}

.app-chatbot-button img {
  width: 100%;
  border-radius: 15px;
}