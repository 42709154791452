.invests_table thead,
.invests_table tbody {
  width: 100%;
}

.invests_table thead {
  border-top: 1px solid var(--gray5);
  border-bottom: 1px solid var(--gray5);
}

.invests_table .body2 tr {
  background: var(--BG);
}

.invests_table .body2 th {
  padding: 10px;
  font-weight: 400 !important;
  color: var(--gray2);
  text-align: left;
}

.invests_table .body2 th:nth-child(1) {
  text-align: center;
}

.invests_table .label2 td {
  font-weight: 500;
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid var(--gray5);
}

.ellipsis {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.invest_table_wrap {
  padding: 20px 0px;
}

.invest_table_wrap .title1 {
  padding-left: 20px;
  border: none;
}

.invest_table_wrap .invests_table th:nth-child(1),
.invest_table_wrap .invests_table td:nth-child(1) {
  width: 25%;
}

.global_laws_table table tbody tr td img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid var(--gray4);
  font-size: 0;
}

/* 기업 보고서 */
.report_wrap {
  height: 400px;
  margin: 20px 0 10px;
  overflow-y: scroll;
}

.report_table {
  width: 100%;
}

.report_table th:nth-child(2),
.report_table td:nth-child(2) {
  width: 40%;
}

.report_table td:nth-child(3) {
  text-align: center;
}

.report_table tbody td {
  padding: 10px;
  border-bottom: 1px solid var(--gray5);
}
