.MarketSummary strong {
  color: var(--secondary);
  display: inline-block;
  margin: 16px 0 5px;
}

.stock_img {
  padding:20px 0 25px;
}

.stock_img img {
  width: 100%;
}

.cont_box .colum {
  padding-top: 20px;
}

.cont_box .colum .heading1 {
  padding: 6px 8px 0 0;
}

.border-R {
  padding-right: 8px;
  border-right: 1px solid var(--gray4);
  margin-right: 8px;
}

.maket_box.column .title1 {
  margin-bottom: 20px;
}

.stock_text span {
  padding-left: 8px;
}
