.intro_container {
  background-color: var(--BG);
  padding: 20px 20px 20px;
}

.intro_nav h1.title2 {
  font-weight: 700 !important;
}

.intro_nav .btn {
  font-weight: 500;
  color: #fff;
}

.intro_wrap .section {
  text-align: center;
}

.intro_wrap h1 {
  padding: 25px 0px;
  font-size: 26px;
  line-height: 35px;
}

.intro_wrap .main_text {
  padding: 20px 0px;
  line-height: 24px;
  word-break: keep-all;
}

.section_b {
  background: #f0f6f9;
  border-radius: 10px;
  margin-top: 30px;
  padding-bottom: 14px;
}

.section_g {
  background: #f4f8f2;
  border-radius: 10px;
  margin-top: 30px;
  padding-bottom: 14px;
}

.text_box {
  padding: 20px 20px 0;
}

.text_box h2 {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
}

.text_box p {
  color: var(--gray1);
  line-height: 24px;
  padding-top: 10px;
  word-break: keep-all;
}

img.intro01 {
  margin-bottom: 5px;
}

img.intro03 {
  width: 100%;
  margin-top: 5px;
}

img.intro04 {
  margin: 35px 0 10px;
}

img.intro05 {
  margin: 20px 0 10px;
}

img.intro06 {
  margin: 26px 0 10px;
}

.intro_wrap button {
  width: 156px;
  height: 35px;
  border-radius: 4px;
  background: var(--secondary);
  color: #fff;
  padding: 10px;
  margin: 5px;
}
