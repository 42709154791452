/* 뉴스 요약 */
.NewsSummary strong {
  color: var(--secondary);
  display: inline-block;
  margin: 20px 0 5px;
}

/* 뉴스 리스트 */
.news_card_wrap {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid var(--gray5);
}

.news_card_wrap .d-between {
  align-items: flex-start;
}

.news_logo_icon {
  height: 16px;
  margin-right: 4px;
  border-radius: 50%;
}

.rating {
  font-size: 12px;
  color: var(--orange);
  letter-spacing: -1px;
}

.news_img {
  display: inline-block;
  width: 70px;
  margin-left: 10px;
}

.news_img img {
  width: 100%;
  border-radius: 8px;
}

.news_title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 10px;
}

.news_date {
  color: var(--gray3);
  padding-top: 10px;
}

/* 관련뉴스 보기 */
.news_related_button .news_related {
  max-height: 110px;
}

.news_open {
  color: var(--primary);
  margin-top: 10px;
}

.news_related {
  overflow-y: scroll;
}

.news_related p {
  overflow: hidden;
  color: var(--gray1);
  text-overflow: ellipsis;
  white-space: nowrap;
  border-top: 1px solid var(--gray5);
  margin-top: 8px;
  padding-top: 8px;
}

/* 뉴스 - 긍정 부정 */
.news_icon {
  background-repeat: no-repeat;
  background-size: 16px;
  width: max-content;
  height: 16px;
  font-size: 12px;
  font-weight: 700;
  padding-left: 18px;
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.news_icon_yes {
  background-image: url(../assets/good.png);
  color: var(--pink);
}

.news_icon_no {
  background-image: url(../assets/no.png);
  color: var(--primary-D);
}

.news_icon_same {
  background-image: url(../assets/same.png);
  color: var(--secondary);
}

/* 뉴스키워드 */
.keyword_list {
  width: 100%;
}

.keyword_list .d-between {
  padding-top: 12px;
}

.nk_title .d-flex p:nth-child(1),
.keyword_list .primary {
  width: 50px;
}

.keyword_list .count {
  color: var(--gray3);
}
