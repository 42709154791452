@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css');
@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
    format('woff');
  font-style: normal;
}

* {
  font-family: 'Pretendard Variable', sans-serif;
}

html {
  position: relative;
}

/* IOS에서 인풋창 선택시 확대기능 막기 */
input,
select:focus,
textarea {
  font-size: 16px;
}

/* 가이드 */
:root {
  /* Theme */
  --primary: #1eb3b8;
  --primary-D: #2c959c;
  --primary2: #5ccba3;
  --primary3: #96e587;
  --primary4: #81dbd1;
  --primary5: #dff1f2;

  --BG: #f8fafb;
  --secondary: #0d1a43;

  /* gary */
  --black: #000;
  --gray1: #333;
  --gray2: #666;
  --gray3: #999;
  --gray4: #dbdbdb;
  --gray5: #efefef;

  /* Basic */
  --yellow: #ffc700;
  --orange: #f59e2d;
  --pink: #ee313b;
  --blue: #0019ff;
  --red: #ff0000;
}

.d-flex {
  display: flex;
  align-items: center;
}

.d-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.d-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.no_data_wrap {
  width: 100%;
  height: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.no_data_wrap img {
  margin-bottom: 18px;
}

.no_data_wrap span {
  padding-top: 6px;
}

/* 공통 */
.body {
  overflow-x: hidden;
}

.nav {
  position: fixed;
  width: 100%;
  padding: 10px;
  background: #fff;
  z-index: 9999;
}

.nav_container {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav .nav_container a {
  padding: 10px;
  display: flex;
  align-items: center;
}

.nav .nav_container .left_icon {
  transform: rotate(0);
}

.nav_container .left_icon {
  width: 7px;
  height: 13px;
  transform: rotate(90deg);
}

.nav_container .right_icon {
  width: 7px;
  height: 13px;
  transform: rotate(-90deg);
  margin-right: 2px;
}
.nav .nav_container h1 {
  font-size: 18px;
}

.line {
  height: 10px;
  background: var(--BG);
}

li::marker {
  content: '';
}

.news_nodata {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.news_nodata .label3 {
  padding: 25px 0 10px;
}

.news_nodata .label4 {
  text-align: center;
}

/* scroll */
.keyword_box .sc-guDLey::-webkit-scrollbar {
  width: 8px;
}

.keyword_box .sc-guDLey::-webkit-scrollbar-thumb {
  background: var(--gray4);
  border-radius: 4px;
  background-clip: padding-box;
  border: 1px solid transparent;
}

.keyword_box .sc-guDLey::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 4px;
}

/* 주식 - 전일대비 */
.up {
  color: var(--red) !important;
}

.same {
  color: var(--gray2) !important;
}

.down {
  color: var(--blue) !important;
}

.basic {
  color: var(--gray1) !important;
}

/* slide */
.slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.slick-list {
  width: 100%;
  height: 26px;
  margin: 0 auto !important;
}

.slick-slider {
  width: 100%;
}

.slick-track {
  height: 27px;
}

.slick-slide {
  width: auto !important;
  margin-right: 4px;
}

/* .slick-prev::before {
  content: url('../assets/prev_btn_slide.svg') !important;
}

.slick-next::before {
  content: url('../assets/next_btn_slide.svg') !important;
} */

.slick-track {
  margin-left: unset !important;
}

/* 키워드 */
.keyword_wrap {
  padding-bottom: 20px;
}

.keyword_wrap ul {
  width: 85%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.keyword_wrap .label2 {
  width: 16%;
  padding-right: 10px;
}

.keyword {
  width: max-content;
  padding: 4px 12px;
  margin: 0 10px 5px 0;
  border-radius: 3px;
  background: var(--gray5);
  color: var(--gray2) !important;
}

/* 버튼 */
.btn {
  padding: 6px 12px;
  border-radius: 5px;
  background: var(--primary);
  color: #fff;
}

.global_btn_wrap {
  width: 144px;
  height: 32px;
  border-radius: 20px;
  background: var(--gray5);
}

.global_btn {
  position: relative;
  height: 28px;
  padding: 6px 10px;
  border-radius: 20px;
  color: var(--gray2) !important;
  border: none;
}

.global_btn.selected {
  background: #fff;
  color: var(--black) !important;
  cursor: default;
}

.global_btn img {
  width: 20px;
  margin-right: 5px;
}

.slide_btn {
  width: max-content;
  height: 26px;
  border-radius: 5px;
  border: 1px solid var(--gray3);
  background: #fff;
  color: var(--gray3, var(--gray3)) !important;
  display: block;
  padding: 5px 16px;
  font-weight: 500 !important;
}

.slide_btn.selected,
.wordcloud_container > .selected {
  border: 1px solid var(--primary);
  background: var(--primary);
  color: #fff !important;
}

.download_wrap {
  display: block;
  margin-top: 35px;
}

.download {
  width: 100%;
  height: 52px;
  padding: 16px;
  border-radius: 10px;
  background: var(--primary);
  color: #fff;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.download img {
  margin-right: 10px;
}

/* 스크롤 */
.scroll {
  overflow-y: scroll;
  max-height: 400px;
}

.scroll::-webkit-scrollbar {
  width: 6px;
}

.scroll::-webkit-scrollbar-thumb {
  background: var(--gray4);
  border-radius: 4px;
  background-clip: padding-box;
  border: 1px solid transparent;
}

.scroll::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 4px;
}

.news_related::-webkit-scrollbar {
  width: 6px;
}

.news_related::-webkit-scrollbar-thumb {
  background: var(--gray4);
  border-radius: 4px;
  background-clip: padding-box;
  border: 1px solid transparent;
}

.news_related::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 4px;
}

h1 {
  font-size: 24px;
  font-weight: 600 !important;
  color: var(--secondary);
}

.title1 {
  font-size: 17px;
  font-weight: 700;
  color: var(--secondary);
}

.title2 {
  font-size: 18px;
  font-weight: 700;
  color: var(--secondary);
}

.title3 {
  font-size: 14px;
  font-weight: 700;
  color: var(--secondary);
}

.label1 {
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
}

.label2 {
  font-size: 13px;
  font-weight: 600;
  color: var(--black);
}

.label3 {
  font-size: 12px;
  font-weight: 700;
  color: var(--black);
}

.label4 {
  font-size: 11px;
  font-weight: 600;
  color: var(--black);
}

.body1 {
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
}

.body2 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.14px;
  color: var(--black);
}

.body3 {
  font-size: 12px;
  font-weight: 400;
  color: var(--black);
}

.body4 {
  font-size: 11px;
  font-weight: 400;
  color: var(--black);
}

.highlight {
  background-color: var(--primary) !important;
  border: 1px solid #fff !important;
  color: #fff !important;
}

.primary {
  color: var(--primary) !important;
}

.primaryD {
  color: var(--primary-D);
}

.secondary {
  color: var(--secondary);
}

.gray3 {
  color: var(--gray3);
}

.point {
  color: var(--pink);
}

.error_text {
  color: var(--red);
  text-align: left;
  padding-left: 5px;
  margin-bottom: 10px;
}

.container {
  padding: 45px 20px 20px;
}

/* 국내,해외 */
.sm_global_box {
  padding: 10px 20px 12px;
}

.guide_box {
  position: relative;
  padding: 5px;
  background: var(--gray5);
}

.guide_box:after {
  content: '';
  position: absolute;
  top: -7px;
  right: 85px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--gray5);
}

.guide_box img {
  margin-right: 8px;
}

.guide_box p {
  line-height: 24px;
  color: var(--gray2);
}

.column .title1 {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--gray4);
  margin-bottom: 10px;
}
